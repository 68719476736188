import Header from '@/components/header'
import Footer from '@/components/footer'
import ClassName from 'clsx'
import Styles from './style.module.scss'

export default function PageWrapper({ soul, children, dark, darkHeader, mainStyle }: any) {
  return (
    <div className={Styles.page_wrapper}>
      <Header soul={soul} dark={darkHeader} />
      <div className={ClassName(Styles.page_main, dark && Styles.dark)} style={mainStyle}>
        {children}
      </div>
      <Footer />
    </div>
  )
}
