export const solution_data = {
  en: [
    {
      id: 'solution-1',
      title: 'C&I Energy Solution',
      content:
        'Primarily catering to the needs of commercial  and industrial business, we aim to offer a range of systematic solutions tailored for every conceivable situation. Moreover, our system boasts scalability, thereby ensuring that it can adapt and grow in parallel with your business expansion',
      path: '/solution/commercial-industrial',
      image_url: 'assets/solution_11.png',
      banner_url: 'assets/solution_banner_1.png',
    },
    {
      id: 'solution-2',
      title: 'Clouding Service',
      content:
        'We offer cloud-based services that include real-time monitoring, data analysis, and remote control. These services help users manage and optimize their energy management systems, improve overall efficiency and reliability, and reduce energy costs.',
      path: '/solution/clouding-service',
      image_url: 'assets/solution_22.png',
      banner_url: 'assets/solution_banner_2.png',
    },
    {
      id: 'solution-3',
      title: 'Design & Consulting',
      content:
        'Our goal is to provide professional guidance and support in the design and implementation process of energy systems, ensuring the success and maximizing the benefits of projects.',
      path: '/solution/design-consulting',
      image_url: 'assets/solution_33.png',
      banner_url: 'assets/solution_banner_3.png',
    },
    {
      id: 'solution-4',
      title: 'Financial Service',
      content:
        'We offer flexible leasing services to help clients obtain reliable energy solutions without the need for upfront investment. Additionally, we provide comprehensive financial loan services to cater to the needs of different businesses.',
      path: '/solution/financial-service',
      image_url: 'assets/solution_44.png',
      banner_url: 'assets/solution_banner_4.png',
    },
  ],
  es: [
    {
      id: 'solution-1',
      title: 'Solución de Energía para Comercios e Industrias (C&I)',
      content:
        'Atendiendo principalmente las necesidades de negocios comerciales e industriales, nuestro objetivo es ofrecer una gama de soluciones sistemáticas adaptadas para cada situación concebible. Además, nuestro sistema cuenta con escalabilidad, garantizando así que pueda adaptarse y crecer al mismo ritmo que la expansión de su negocio.',
      path: '/solution/ci-energy-solution',
      image_url: 'assets/solution_11.png',
      banner_url: 'assets/solution_banner_1.png',
    },
    {
      id: 'solution-2',
      title: 'Servicio de computación en la nube',
      content:
        'Ofrecemos servicios basados en la nube que incluyen monitoreo en tiempo real, análisis de datos y control remoto. Estos servicios ayudan a los usuarios a gestionar y optimizar sus sistemas de gestión de energía, mejorar la eficiencia y confiabilidad en general, y reducir los costos energéticos.',
      path: '/solution/clouding-service',
      image_url: 'assets/solution_22.png',
      banner_url: 'assets/solution_banner_2.png',
    },
    {
      id: 'solution-3',
      title: 'Diseño y Consultoría',
      content:
        'Nuestro objetivo es brindar orientación y apoyo profesional en el proceso de diseño e implementación de sistemas de energía, garantizando el éxito y maximizando los beneficios de los proyectos',
      path: '/solution/design-consulting',
      image_url: 'assets/solution_33.png',
      banner_url: 'assets/solution_banner_3.png',
    },
    {
      id: 'solution-4',
      title: 'Servicio Financiero',
      content:
        'Ofrecemos servicios de arrendamiento flexibles para ayudar a los clientes a obtener soluciones energéticas confiables sin la necesidad de una inversión inicial. Además, proporcionamos servicios integrales de préstamos financieros para satisfacer las necesidades de diferentes empresas.',
      path: '/solution/financial-service',
      image_url: 'assets/solution_44.png',
      banner_url: 'assets/solution_banner_4.png',
    },
  ],
  cn: [
    {
      id: 'solution-1',
      title: '工商业储能设备系统',
      content:
        '我们主要满足商业和工业企业的需求，旨在为各种可能的情况提供一系列系统的解决方案。此外，我们的系统具有可扩展性，从而确保它能够随着您的业务扩展而适应和增长',
      path: '/solution/ci-energy-solution',
      image_url: 'assets/solution_11.png',
      banner_url: 'assets/solution_banner_1.png',
    },
    {
      id: 'solution-2',
      title: '云平台的储能服务',
      content:
        '我们提供基于云平台的储能系统服务，包括实时监测、数据分析和远程控制等服务。这些服务可以帮助用户管理和优化其储能系统，提高电力系统的效率和可靠性，降低能源成本。',
      path: '/solution/clouding-service',
      image_url: 'assets/solution_22.png',
      banner_url: 'assets/solution_banner_2.png',
    },
    {
      id: 'solution-3',
      title: '能源咨询和设计',
      content: '我们的目标是在能源系统的设计和实施过程中提供专业的指导和支持，确保项目的成功和效益最大化。',
      path: '/solution/design-consulting',
      image_url: 'assets/solution_33.png',
      banner_url: 'assets/solution_banner_3.png',
    },
    {
      id: 'solution-4',
      title: '融资与租赁',
      content:
        '我们提供灵活的储能设备租赁服务，帮助客户在没有购买储能系统的资金的情况下获得可靠的能源解决方案。同时提供一站式金融贷款服务，以满足不同企业的需求。',
      path: '/solution/financial-service',
      image_url: 'assets/solution_44.png',
      banner_url: 'assets/solution_banner_4.png',
    },
  ],
}

export const service_center = {
  en: [
    {
      title: 'Download',
      path: '/service/download',
    },
    {
      title: 'Warranty',
      path: '/service/quality',
    },
    {
      title: 'Tech Support',
      path: '/service/support',
    },
    {
      title: 'Training & Education',
      path: '/service/train',
    },
    {
      title: 'Customer Service',
      path: '/service/afterSale',
    },
  ],
  es: [
    {
      title: 'Descargar',
      path: '/service/download',
    },
    {
      title: 'Garantía',
      path: '/service/quality',
    },
    {
      title: 'Soporte Técnico',
      path: '/service/support',
    },
    {
      title: 'Capacitación y Educación',
      path: '/service/train',
    },
    {
      title: 'Servicio al Cliente',
      path: '/service/afterSale',
    },
  ],
  cn: [
    {
      title: '下载中心',
      path: '/service/download',
    },
    {
      title: '质保协议',
      path: '/service/quality',
    },
    {
      title: '技术支持',
      path: '/service/support',
    },
    {
      title: '培训与教育',
      path: '/service/train',
    },
    {
      title: '售后支持',
      path: '/service/afterSale',
    },
  ],
}

export const about_us = {
  en: [
    {
      id: 'about-1',
      title: 'Company Profile',
      path: '/about',
      image_url: 'assets/about_1.png',
      banner_url: 'assets/about_banner_1.png',
    },
    {
      id: 'about-2',
      title: 'Join Us',
      path: '/join',
      image_url: 'assets/about_2.png',
      banner_url: 'assets/about_banner_2.png',
    },
    {
      id: 'about-3',
      title: 'Contact Us',
      path: '/contact',
      image_url: 'assets/about_1.png',
      banner_url: 'assets/about_banner_1.png',
    },
  ],
  es: [
    {
      id: 'about-1',
      title: 'Perfil de la Empresa',
      path: '/about',
      image_url: 'assets/about_1.png',
      banner_url: 'assets/about_banner_1.png',
    },
    {
      id: 'about-2',
      title: 'Únete a Nosotros',
      path: '/join',
      image_url: 'assets/about_2.png',
      banner_url: 'assets/about_banner_2.png',
    },
    {
      id: 'about-3',
      title: 'Contáctanos',
      path: '/contact',
      image_url: 'assets/about_1.png',
      banner_url: 'assets/about_banner_1.png',
    },
  ],
  cn: [
    {
      id: 'about-1',
      title: '企业简介',
      path: '/about',
      image_url: 'assets/about_1.png',
      banner_url: 'assets/about_banner_1.png',
    },
    {
      id: 'about-2',
      title: '加入我们',
      path: '/join',
      image_url: 'assets/about_1.png',
      banner_url: 'assets/about_banner_1.png',
    },
    {
      id: 'about-3',
      title: '联系我们',
      path: '/contact',
      image_url: 'assets/about_1.png',
      banner_url: 'assets/about_banner_1.png',
    },
  ],
}
