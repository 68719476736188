import { solution_1 } from './solution-1'
import { solution_2 } from './solution-2'
import { solution_3 } from './solution-3'
import { solution_4 } from './solution-4'

export interface SolutionProps {
  path: string
  title: string
  description?: string
  cover: string
  solutionCover: string
  banner: string
  solution?: string[]
  application?: { title: string; description?: string; image: string }[]
  product?: { title: string; description?: string; image: string }[]
  advantage?: string
  success_case?: { title: string; image: string }[]
}

const solutionEn = [solution_1, solution_2, solution_3, solution_4]

export const i18nSolution = {
  en: solutionEn,
  es: solutionEn,
  cn: solutionEn,
}
