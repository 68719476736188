import React, { useState, useEffect, useCallback, CSSProperties } from 'react'
import debounce from 'lodash.debounce'
import type { AreaShape, HotSpotArea, UseImageMapOptions } from './type'

// 坐标缩放处理器
const scaleCoords = (
  original: number[],
  naturalSize: { width: number; height: number },
  displaySize: { width: number; height: number },
  shape: AreaShape
) => {
  const scaleX = displaySize.width / naturalSize.width
  const scaleY = displaySize.height / naturalSize.height

  switch (shape) {
    case 'rect':
      return [
        original[0] * scaleX, // x1
        original[1] * scaleY, // y1
        original[2] * scaleX, // x2
        original[3] * scaleY, // y2
      ]

    case 'circle':
      // 圆形半径取平均值以适应非等比例缩放
      const avgScale = (scaleX + scaleY) / 2
      return [
        original[0] * scaleX, // cx
        original[1] * scaleY, // cy
        original[2] * avgScale, // r
      ]

    case 'poly':
      return original.map((coord, index) => (index % 2 === 0 ? coord * scaleX : coord * scaleY))

    default:
      return original
  }
}

export default function UseImageMap(
  imgRef: React.RefObject<HTMLImageElement>,
  areas: HotSpotArea[] = [],
  options: UseImageMapOptions = {}
) {
  const {
    showDebug = false,
    debugStyle = {
      border: '2px solid red',
      backgroundColor: 'rgba(255, 0, 0, 0.5)',
    },
    debounce: debounceTime = 150,
    nameSpace,
  } = options
  const [scaledAreas, setScaledAreas] = useState<HotSpotArea[]>([])

  console.log('areas: ', areas)

  const updateCoords = useCallback(
    debounce(() => {
      if (!imgRef.current) return

      const naturalSize = {
        width: imgRef.current.naturalWidth,
        height: imgRef.current.naturalHeight,
      }

      const displaySize = imgRef.current.getBoundingClientRect()

      setScaledAreas(
        areas?.map(area => ({
          ...area,
          coords: scaleCoords(area.coords, naturalSize, displaySize, area.shape || 'rect'),
        }))
      )
    }, debounceTime),
    [imgRef, debounceTime] // 确保依赖项正确
  )

  // 监听变化
  useEffect(() => {
    const img = imgRef.current
    if (!img) return

    img.addEventListener('load', updateCoords)
    const resizeObserver = new ResizeObserver(updateCoords)
    resizeObserver.observe(img)

    return () => {
      img.removeEventListener('load', updateCoords)
      resizeObserver.disconnect()
      updateCoords.cancel()
    }
  }, [imgRef, updateCoords])

  // 生成热区元素
  const getMapElements = useCallback(
    () => (
      <map name={`#${nameSpace}`}>
        {scaledAreas.map((area, index) => {
          const { coords, shape = 'rect', onClick, onHover } = area
          return (
            <area
              key={index}
              shape={shape}
              coords={coords.join(',')}
              onClick={onClick}
              onMouseEnter={() => onHover?.(true)}
              onMouseLeave={() => onHover?.(false)}
              style={{ cursor: onClick ? 'pointer' : 'default' }}
              aria-label={`Hotspot area ${index + 1}`}
            />
          )
        })}
      </map>
    ),
    [scaledAreas]
  )

  // 可视化调试层
  const getDebugOverlays = useCallback(() => {
    if (!showDebug) return null

    return scaledAreas.map((area, index) => {
      const { shape = 'rect', coords, debugStyle: areaDebugStyle } = area
      const mergedStyle: CSSProperties = { pointerEvents: 'none', ...debugStyle, ...areaDebugStyle }

      switch (shape) {
        case 'rect': {
          const [x1, y1, x2, y2] = coords
          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: x1,
                top: y1,
                width: x2 - x1,
                height: y2 - y1,
                ...mergedStyle,
              }}
            />
          )
        }

        case 'circle': {
          const [cx, cy, r] = coords
          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: cx - r,
                top: cy - r,
                width: r * 2,
                height: r * 2,
                borderRadius: '50%',
                ...mergedStyle,
              }}
            />
          )
        }

        case 'poly': {
          const points = coords
            .map((coord, index) => `${coord}${index % 2 === 0 ? 'px ' : 'px, '}`)
            .join('')
            .slice(0, -2)

          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                clipPath: `polygon(${points})`,
                width: '100%',
                height: '100%',
                ...mergedStyle,
              }}
            />
          )
        }

        default:
          return null
      }
    })
  }, [scaledAreas, showDebug, debugStyle])

  return { getMapElements, getDebugOverlays, scaledAreas }
}
