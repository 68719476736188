import { useState } from 'react'
import { Image } from 'react-bootstrap'
import { Flex } from 'antd'
import ClassName from 'clsx'
import Point from './point'
import Styles from './style.module.scss'
import earthImage from '@/assets/img/earth.png'
import { pointArr } from '@/assets/content/earth-point.ts'

export default function Index() {
  const [activeIndex, setActiveIndex] = useState(0)
  const content = pointArr?.[activeIndex]?.children

  return (
    <div className={Styles.wrapper}>
      <h2 className={Styles.title}>Our business is spread all over the world</h2>
      <Flex align='center' justify='center' gap={80} className={Styles.main}>
        <div className={Styles.earthMap}>
          <Image className={Styles.earthImage} src={earthImage} />
          {pointArr?.map(({ left, top }, index) => (
            <Point
              key={index}
              left={left}
              top={top}
              active={activeIndex === index}
              onSelect={() => setActiveIndex(index)}
              onMouseEnter={() => console.log(10086)}
            />
          ))}
        </div>
        <div
          className={ClassName(
            Styles.right,
            content?.length === 3 && Styles.three,
            content?.length === 2 && Styles.two,
            content?.length === 1 && Styles.one
          )}
        >
          {content?.map((d, index) => (
            <Flex vertical className={Styles.board} key={`${activeIndex}-${index}`}>
              <div className={Styles.label}>{d.title}</div>
              <Flex className={Styles.info}>
                <Flex vertical>
                  <div className={Styles.type}>Size</div>
                  <div>{d.size}</div>
                </Flex>
                <Flex vertical>
                  <div className={Styles.type}>Location</div>
                  <div>{d.location}</div>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </div>
      </Flex>
    </div>
  )
}
