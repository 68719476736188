import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { i18nSolution } from '@/assets/content/solution'
import useLang from '@/utils/hooks/use-lang'
import Styles from './style.module.scss'
import ClassName from 'clsx'

export function Accordion() {
  const navigate = useNavigate()
  const [active, setActive] = useState(0)
  const [lang] = useLang()
  const content = i18nSolution?.[lang] || []

  const handleToggle = useCallback(
    debounce(index => {
      setActive(index)
    }, 150),
    []
  )

  return (
    <div className={Styles.accordion}>
      {content?.map((item, index) => (
        <div
          key={index}
          className={ClassName(Styles.item, active === index && Styles.active)}
          onMouseEnter={() => handleToggle(index)}
          style={{ background: `url(${item.cover}) no-repeat center/cover` }}
        >
          <h5 className={Styles.staticTitle}>{item.title}</h5>
          <div className={Styles.content}>
            <div className={Styles.contentInner}>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              <div className={Styles.viewMore} onClick={() => navigate(`/solution/${item.path}`)}>
                More
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default function Index() {
  return (
    <div className={Styles.wrapper}>
      <h2 className={Styles.title}>Strong supply chain makes costs controllable</h2>
      <Accordion />
    </div>
  )
}
