import { useState } from 'react'
import { Flex } from 'antd'
import { Image } from 'react-bootstrap'
import ImageWrapper from '@/components/image-wrapper'
import ClassName from 'clsx'
import Styles from './style.module.scss'
import useLang from '@/utils/hooks/use-lang'
import { useNavigate } from 'react-router-dom'
import { i18nSolution } from '@/assets/content/solution'

export default function Index() {
  const [activeIndex, setActiveIndex] = useState(0)
  const navigate = useNavigate()
  const [lang] = useLang()
  const content = i18nSolution?.[lang] || []
  const solutionCover = content?.[activeIndex]?.solutionCover
  const covers = content?.map(({ solutionCover }) => solutionCover)
  const current = content[activeIndex]

  return (
    <ImageWrapper backgroundImage={solutionCover} className={Styles.container}>
      {/* {covers?.map(d => (
        <Image key={d} srcSet={d} style={{ width: 0, height: 0, opacity: 0, display: 'contents' }} />
      ))} */}
      <div className={Styles.main}>
        <Flex justify='space-around' className={Styles.tabs}>
          {content?.map((d, i) => (
            <Flex
              align='center'
              justify='center'
              className={ClassName(Styles.tabItem, activeIndex === i && Styles.active, Styles.element)}
              key={i}
              onMouseEnter={() => setActiveIndex(i)}
            >
              {d.title}
            </Flex>
          ))}
        </Flex>
        <Flex key={activeIndex} vertical align='center' className={Styles.content}>
          <h4 className={Styles.title}>{current.title}</h4>
          <div className={Styles.description}>{current.description}</div>
          <strong className={Styles.moreButton} onClick={() => navigate(current?.path)}>
            More
          </strong>
        </Flex>
      </div>
    </ImageWrapper>
  )
}
