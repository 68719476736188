import { Flex } from 'antd'
import banner from '@/assets/img/carbon/banner.jpg'
import Styles from './style.module.scss'

export default function Index() {
  return (
    <Flex vertical align='center' className={Styles.wrapper} style={{ backgroundImage: `url(${banner})` }}>
      {/* <Flex vertical align='center' className={Styles.wrapper} style={{ backgroundImage: 'url(assets/wind-power.webp)' }}> */}
      <h2 className={Styles.title}>Carbon Footprint Management</h2>
      <div className={Styles.description}>
        During our development, Carus prioritizes ESG commitments, focusing on tracking and managing the carbon
        footprint of its system products. Despite industry-wide challenges, we continue to build a professional ESG team
        and maintain close collaboration with core suppliers to advance ESG initiatives and carbon footprint monitoring.
        Through Carus equipment, we aim to achieve end-to-end carbon transparency and support customers in jointly
        achieving carbon neutrality goals, enabling comprehensive carbon footprint reporting across value chains.
      </div>
      <Flex justify='space-evenly' className={Styles.bottom}>
        <Flex vertical align='center'>
          {/* <Image src='assets/svg/icon-safety.svg' /> */}
          <div className={Styles.label}>Professional Team</div>
          <div className={Styles.content}>Management Monitoring And Indicating Status Of Battery And System</div>
        </Flex>
        <Flex vertical align='center'>
          {/* <Image src='assets/svg/icon-comprehensive.svg' /> */}
          <div className={Styles.label}>Compliant With Policies</div>
          <div className={Styles.content}>Standard Interface For Communication AndHV Connection</div>
        </Flex>
        <Flex vertical align='center'>
          {/* <Image src='assets/svg/icon-24h.svg' /> */}
          <div className={Styles.label}>Efficient And High Reach</div>
          <div className={Styles.content}>Liquid Cooling System + Cong Life Time LFP Cell And Multiple Protection</div>
        </Flex>
      </Flex>
    </Flex>
  )
}
