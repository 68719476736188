import { Flex } from 'antd'
import { Image } from 'react-bootstrap'

import Styles from './style.module.scss'

export function ProductItem({ id, image, title, description }: any) {
  return (
    <Flex vertical align='center' className={Styles.listItemWrapper}>
      <Image className={Styles.productImage} src={image} />
      <div className={Styles.content}>
        <h5>{title}</h5>
        {/* <div className={Styles.description}>{description}</div> */}
        {/* <div className={Styles.more}>
          <Image src='assets/arrow_right.svg' />
        </div> */}
      </div>
    </Flex>
  )
}

const MODULE_ID = 'product'

export default function Index({ data }: { data?: any[] }) {
  return (
    <div id={MODULE_ID} className={Styles.wrapper}>
      <h2>Product</h2>
      <Flex justify='center' className={Styles.list}>
        {data?.map(({ id, image, title, description }) => (
          <ProductItem key={title} id={title} image={image} title={title} description={description} />
        ))}
      </Flex>
    </div>
  )
}
