import { Carousel, Image } from 'react-bootstrap'
import useLang from '@/utils/hooks/use-lang'
import Styles from './style.module.scss'
import { useState, useEffect } from 'react'
import ClassName from 'clsx'
import banner from './banner.png'

const bannerText = [
  {
    title: 'Industry-Leading Solutions & Execution',
    description:
      'Mastering complex energy projects with precision-engineered, end-to-end solutions tailored for success',
  },
  {
    title: 'Superior Cost Efficiency & Competitive Edge',
    description:
      'Advanced financial modeling and aggressive cost optimization, backed by ful-spectrum engineering licenses for seamlessexecution. Powerful Technical Expertise',
  },
  {
    title: 'Powerful Technical Expertise & Scalable Innovation',
    description:
      'Proven track record in high-capacity energy storage, ensuring flawless deployment regulatory excellence, and future-readyscalability',
  },
]

function UncontrolledExample() {
  const [lang] = useLang()
  const [content, setContent] = useState(bannerText[0])

  useEffect(() => {
    let index = 0
    const timed = setInterval(() => {
      if (index >= bannerText.length - 1) {
        index = 0
      } else {
        index++
      }
      console.log('index: ', index)
      setContent(bannerText[index])
    }, 4000)

    return () => clearInterval(timed)
  }, [])

  return (
    <Carousel indicators={false} controls={false}>
      <Carousel.Item>
        <Image className={Styles.image} src={banner} />
        <Carousel.Caption className={ClassName(Styles.caption)} key={content?.title}>
          <h2>{content?.title}</h2>
          <div className={Styles.description}>{content?.description}</div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default UncontrolledExample
