import { Image } from 'react-bootstrap'
import { Flex } from 'antd'
import PageWrapper from '@/components/page-wrapper'
import IconCare from '@/assets/svg/icon-care.svg'
import IconProcess from '@/assets/svg/icon-process.svg'
import IconCredit from '@/assets/svg/icon-credit.svg'
import IconCost from '@/assets/svg/icon-cost.svg'
import IconCircle from '@/assets/svg/icon-circle.svg'
import Banner from './banner'
import Solution from './solution'
import Styles from './style.module.scss'

export default function Carbon() {
  return (
    <PageWrapper soul>
      <Banner />
      <Solution />
      <Flex vertical className={Styles.experienceProcess}>
        <div>
          <h3>Our team has many years of experience</h3>
          <Flex>
            <div className={Styles.text}>
              Our ESG specialists bring over 8 years of expertise in collaborating with Apple's ESG team, delivering
              exceptional contributions to Apple's ESG framework and carbon footprint management{' '}
            </div>
            <Image src={IconCare} className={Styles.iconCare} />
          </Flex>
        </div>
        <Flex vertical align='flex-end'>
          <h3 className={Styles.title}>Carbon footprint tracking and emission reduction report issuance process</h3>
          <Image src={IconProcess} className={Styles.IconProcess} />
        </Flex>
      </Flex>

      <Flex vertical className={Styles.support}>
        <div className={Styles.abstract}>
          We provide post-installation carbon reduction reports showing exactly how much CO₂ your facility has avoided
          (in metric tons). Data is tracked via real-time monitoring and verified by SGS/TÜV to meet global standards
          like HHS 2035. Available upon request for ESG compliance or audits.
        </div>
        <h2>After installation, we can provide</h2>
        <div className={Styles.card}>
          <h4>Provide relevant data</h4>
          <div className={Styles.grid}>
            <div className={Styles.item}>
              <Image src={IconCircle} className={Styles.iconCircle} />
              <div className={Styles.label}>Basic System Information</div>
              <div>Installation location, capacity, model, and technical specifications</div>
            </div>
            <div className={Styles.item}>
              <Image src={IconCircle} className={Styles.iconCircle} />
              <div className={Styles.label}>Third-Party Verification</div>
              <div>Independent audit data ensuring accuracy and transparency of carbon footprint calculations</div>
            </div>
            <div className={Styles.item}>
              <Image src={IconCircle} className={Styles.iconCircle} />
              <div className={Styles.label}>Lifecycle Data</div>
              <div>Data covering production, transportation, installation, operation, and disposal</div>
            </div>
            <div className={Styles.item}>
              <Image src={IconCircle} className={Styles.iconCircle} />
              <div className={Styles.label}>Maintenance & Operation Records</div>
              <div>Maintenance logs, fault records, and service histories</div>
            </div>
            <div className={Styles.item}>
              <Image src={IconCircle} className={Styles.iconCircle} />
              <div className={Styles.label}>Operational Performance Data</div>
              <div>Real-time metrics (charge/discharge efficiency, usage cycles, energy throughput)</div>
            </div>
            <div className={Styles.item}>
              <Image src={IconCircle} className={Styles.iconCircle} />
              <div className={Styles.label}>Production & Installation Data</div>
              <div>Energy consumption during manufacturing and installation process data</div>
            </div>
            <div className={Styles.item}>
              <Image src={IconCircle} className={Styles.iconCircle} />
              <div className={Styles.label}>Advanced Monitoring Technologies</div>
              <div>Technologies (e.g., blockchain) for real-time, precise carbon emission tracking</div>
            </div>
            <div className={Styles.item}>
              <Image src={IconCircle} className={Styles.iconCircle} />
              <div className={Styles.label}>Renewable Energy Attribute Certification</div>
              <div>Certification data (RECs, GOs, I-RECs) verifying renewable energy supply</div>
            </div>
          </div>
        </div>

        <div className={Styles.bottom}>
          <h2>Benefits brought to customers</h2>
          <Flex className={Styles.bottomContent}>
            <div className={Styles.card}>
              <Image src={IconCredit} className={Styles.icon} />
              <div>Dramatically Reduce Reliance on Carbon Credits</div>
              <ul>
                <li>Achieve direct emission cuts via system optimization (e.g., peak-shaving).</li>
                <li>
                  Dynamic data for ESG disclosure and carbon tax offsets, minimizing the need for external carbon
                  credits.
                </li>
              </ul>
            </div>
            <div className={Styles.card}>
              <Image src={IconCost} className={Styles.icon} />
              <div>Economic Benefits</div>
              <ul>
                <li>Energy savings: 20%-30%.</li>
                <li>Payback period: 18-24 months.</li>
              </ul>
            </div>
          </Flex>
        </div>
      </Flex>
    </PageWrapper>
  )
}
