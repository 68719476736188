import { useNavigate } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import ClassName from 'clsx'
import Styles from './style.module.scss'

export default function Index({ shadow }) {
  const navigate = useNavigate()

  return (
    <div className={ClassName(Styles.wrapper, shadow && Styles.shadow)} id='contact_us'>
      <div className={Styles.card}>
        <h2>Contact Us</h2>
        <div>
          Explore our range of cutting-edge solar panels and inverters designed to maximize efficiency and
          sustainability.
        </div>
        <Image className={Styles.arrow} src={`assets/svg/arrow-black.svg`} onClick={() => navigate('/')} />
      </div>
    </div>
  )
}
