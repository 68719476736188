import { Image } from 'react-bootstrap'
import Styles from './style.module.scss'

export default function Index() {
  return (
    <div className={Styles.wrapper}>
      <h2>EPC Turnkey Capabilities</h2>
      <div className={Styles.description}>Full-Cycle services</div>
      <Image className={Styles.cycle} src={`assets/cycle.webp`} />
    </div>
  )
}
