import { Image } from 'react-bootstrap'
import ClassName from 'clsx'
import Styles from './style.module.scss'

export default function Point({ left, top, active, onSelect }) {
  return (
    <div className={Styles.pointWrapper} style={{ left, top }} onMouseEnter={onSelect}>
      <Image className={ClassName(Styles.coordinate, active && Styles.visible)} src={`assets/coordinate.svg`} />
      <div className={ClassName(Styles.point, !active && Styles.visible)}>
        <div className={Styles.circle}>
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        {/* <div class='index_banner_item_button'>
        <div class='index_banner_item_btn'>
          <div class='index_banner_item_width'>
            <a class='flex' href='/network.html'>
              <div class='index_banner_item_icon flex'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 733.87 716.8'>
                  <path d='M597.33,0c29,0,51.21,22.19,51.21,51.2V665.6h59.73c13.65,0,25.6,11.95,25.6,25.6s-12,25.6-25.6,25.6H25.6C12,716.8,0,704.85,0,691.2s12-25.6,25.6-25.6H85.33V51.2c0-29,22.19-51.2,51.21-51.2Zm0,51.2H136.54V665.6H597.33ZM404.48,356.69c10.24,10.24,10.24,25.6,0,35.85L358.4,438.61h76.8c22.19,0,34.13,25.61,20.48,42.67L329.39,607.57c-10.24,10.24-25.6,10.24-35.84,0s-10.24-25.6,0-35.84l80.21-80.21H297c-22.19,0-34.13-25.6-20.48-42.67l1.71-1.7,90.45-90.46c5.12-5.12,11.95-6.82,18.77-6.82a26.08,26.08,0,0,1,17.07,6.82ZM494.93,102.4c29,0,51.2,22.19,51.2,51.2V273.07c0,29-22.18,51.2-51.2,51.2h-256c-29,0-51.2-22.19-51.2-51.2V153.6c0-29,22.19-51.2,51.2-51.2Zm0,51.2h-256V273.07h256Z'></path>
                </svg>
              </div>
              <div class='index_banner_item_text'>PV&amp;ESS&amp;EV Charging</div>
              <div class='index_banner_item_jt flex'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 819.13 1447.05'>
                  <path
                    d='M819.72,726.84l-707-706.65a65.38,65.38,0,0,0-93,.39,66.73,66.73,0,0,0,.38,93.82l612.4,612.11L20.26,1334.38a66.65,66.65,0,0,0-.72,93.82,65.4,65.4,0,0,0,93.05.72Z'
                    transform='translate(-0.59 -0.94)'
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div> */}
      </div>
    </div>
  )
}
