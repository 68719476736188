import PageWrapper from '@/components/page-wrapper'
import Banner from './banner'
// import Advantage from './advantage'
import Solution from './solution'
import Contact from '@/pages/homepage/contact'

export default function Index() {
  return (
    <PageWrapper soul darkHeader>
      <Banner />
      {/* <Advantage /> */}
      <Solution />
      <Contact />
    </PageWrapper>
  )
}
