export const pointArr = [
  {
    left: '27%',
    top: '47%',
    children: [
      {
        title: 'Grid Support',
        size: '70MW150MWh',
        location: 'San Jacinto.CA',
      },
      {
        title: 'Grid Support',
        size: '20MW/50MWh',
        location: 'Ortega, CA',
      },
      {
        title: 'Grid Support',
        size: '20MW/50MWh',
        location: 'Desert, CA',
      },
    ],
  },
  // {
  //   left: '0%',
  //   top: '0%',
  //   children: [
  //     {
  //       title: 'Grid Support',
  //       size: '20MW/50MWh',
  //       location: 'Ortega, CA',
  //     },
  //   ],
  // },
  // {
  //   left: '0%',
  //   top: '0%',
  //   children: [
  //     {
  //       title: 'Grid Support',
  //       size: '20MW/50MWh',
  //       location: 'Desert, CA',
  //     },
  //   ],
  // },
  {
    left: '37%',
    top: '46%',
    children: [
      {
        title: 'Grid Support',
        size: '20MW/90MWh',
        location: 'Jicarilla, NM',
      },
    ],
  },
  {
    left: '58%',
    top: '46%',
    children: [
      {
        title: 'Peak Shaving/ Frequency Reg',
        size: '12MW/24MWh',
        location: 'Danville, VA',
      },
    ],
  },
  {
    left: '31%',
    top: '48%',
    children: [
      {
        title: 'Demand Charge Mgmt',
        size: '5MW/1.2MWh',
        location: 'Mira Loma, CA',
      },
    ],
  },
  {
    left: '25%',
    top: '43%',
    children: [
      {
        title: 'EV/Dmd Chrg Mgmt',
        size: '1.5MW/3MWh',
        location: 'Hayward, CA',
      },
    ],
  },
  {
    left: '27%',
    top: '34%',
    children: [
      {
        title: 'Backup/Peak Power Reduction',
        size: '5MW/11MWh',
        location: 'Portland, OR',
      },
    ],
  },
  {
    left: '57%',
    top: '38%',
    children: [
      {
        title: 'Global Adjustment',
        size: '15MW/40MWh (8 Sites)',
        location: 'Ontario, Canada',
      },
    ],
  },
]
