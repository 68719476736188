import ClassName from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Controller, EffectFade } from 'swiper'
import Styles from './style.module.scss'

const MODULE_ID = 'success_case'
const showLength = 4

export default function Case({ data }) {
  if (!data?.length) return null

  return (
    <div id={MODULE_ID} className={Styles.application}>
      <h2 style={{ textTransform: 'capitalize' }}>{MODULE_ID.split('_').join(' ')}</h2>
      <Swiper
        modules={[Navigation, Controller, EffectFade]}
        slidesPerView={showLength}
        centeredSlides={data?.length > showLength ? true : false}
        centeredSlidesBounds={true}
        fadeEffect={{ crossFade: true }}
        navigation={{
          prevEl: `.navPrev`,
          nextEl: `.navNext`,
        }}
        spaceBetween={30}
        className={ClassName(Styles.slideWrapper, data?.length <= showLength && Styles.center)}
      >
        {data?.map(item => (
          <SwiperSlide
            key={item.title}
            className={Styles.slide}
            style={{
              background: `url(${item.image}) no-repeat center/cover`,
            }}
          >
            {/* <Image src={item.image} alt={item.title} className={Styles.mainImage} /> */}
            <div className={Styles.content}>
              {/* <Image src={item.image} alt={item.title} className={Styles.mainImage} /> */}
              {/* <div className={Styles.textContainer}>
                <h5 className={Styles.title}>{item.title}</h5>
                <p className={Styles.description}>{item.description}</p>
              </div> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
