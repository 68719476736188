import homepage from '@/assets/img/solution-3/homepage.jpg'
import solution from '@/assets/img/solution-3/solution.jpg'
import Banner from '@/assets/img/solution-3/banner.png'
import advantage from '@/assets/img/solution-3/advantage.png'
import solution_image_1 from '@/assets/img/solution-3/solution-image-1.png'
import solution_image_2 from '@/assets/img/solution-3/solution-image-2.png'
import solution_image_3 from '@/assets/img/solution-3/solution-image-3.png'
import application_1 from '@/assets/img/solution-3/application/application-1.png'
import application_2 from '@/assets/img/solution-3/application/application-2.png'
import application_3 from '@/assets/img/solution-3/application/application-3.png'
import application_4 from '@/assets/img/solution-3/application/application-4.png'
import application_5 from '@/assets/img/solution-3/application/application-5.png'
import application_6 from '@/assets/img/solution-3/application/application-6.png'
import product_1 from '@/assets/img/solution-3/product/product-1.png'
import product_2 from '@/assets/img/solution-3/product/product-2.png'
import successCase_1 from '@/assets/img/solution-3/success-case/success-case-1.png'
import successCase_2 from '@/assets/img/solution-3/success-case/success-case-2.png'
import successCase_3 from '@/assets/img/solution-3/success-case/success-case-3.png'

export const solution_3 = {
  path: 'emergency-disaster-relief',
  title: 'Emergency&Disaster Relief Solution',
  description:
    'Critical energy solutions for healthcare, communication, shelter, rescue, water access, and recovery during emergencies or disasters.',
  cover: homepage,
  solutionCover: solution,
  banner: Banner,
  solution: [solution_image_1, solution_image_2, solution_image_3],
  advantage: advantage,
  application: [
    {
      title: 'Medical Facilities and Field Hospitals',
      description:
        'Provides power for life-saving medical equipment, refrigeration for medicines, and lighting for emergency surgeries.',
      image: application_1,
    },
    {
      title: 'Communication Systems',
      description:
        'Ensures the operation of communication devices, such as radios and satellite phones, to coordinate rescue efforts and maintain contact with affected populations.',
      image: application_2,
    },
    {
      title: 'Temporary Shelters and Relief Centers',
      description:
        'Supplies electricity for lighting, heating, cooling, and charging electronic devices in shelters for displaced individuals.',
      image: application_3,
    },
    {
      title: 'Search and Rescue Operations',
      description:
        'Powers tools and equipment used by rescue teams, such as drones, cutting tools, and lighting systems.',
      image: application_4,
    },
    {
      title: 'Water Purification and Distribution',
      description:
        'Supports water purification systems and pumps to provide clean drinking water in disaster-affected areas.',
      image: application_5,
    },
    {
      title: 'Infrastructure Repair and Recovery',
      description: 'Assists in powering construction equipment and tools for rebuilding damaged infrastructure.',
      image: application_6,
    },
  ],
  product: [
    {
      title: 'CARUS-MB-1500',
      image: product_1,
    },
    {
      title: 'CARUS-MB-93',
      image: product_2,
    },
  ],
  success_case: [],
}
