import { Image } from 'react-bootstrap'
import IconBulb from '@/assets/svg/icon-bulb.svg'
import Styles from './style.module.scss'
import { Flex } from 'antd'

const data = [
  {
    title: 'Carbon Tracking System',
    description: 'Partner with core suppliers to enhance supply chain transparency.',
  },
  {
    title: 'Third-Party Audits',
    description: 'Ensure compliance through verified carbon footprint reports.',
  },
  {
    title: 'Closed-Loop Model by 2030',
    description: 'Optimize ESG frameworks for full lifecycle management.',
  },
  {
    title: 'Client Carbon Neutrality',
    description: 'Deliver actionable reports; Support AdventHealth’s 2035 goal.',
  },
]

export default function Index() {
  return (
    <div className={Styles.wrapper}>
      <h2>Provide professional carbon neutrality solutions</h2>
      <Flex justify='center' className={Styles.main}>
        <Flex align='center' className={Styles.imageWrapper}>
          <Image src={IconBulb} />
        </Flex>
        <Flex vertical className={Styles.content}>
          {data?.map(({ title, description }, index) => (
            <Flex vertical className={Styles.cell}>
              <span>{`0${index + 1}`}</span>
              <h5>{title}</h5>
              <div>{description}</div>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </div>
  )
}
