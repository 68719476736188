import { type SolutionProps } from './solution'
import homepage from '@/assets/img/solution-1/homepage.png'
import solutionCover from '@/assets/img/solution-1/solution.jpg'
import banner from '@/assets/img/solution-1/banner.png'
import solution_image_1 from '@/assets/img/solution-1/solution-image-1.png'
import solution_image_2 from '@/assets/img/solution-1/solution-image-2.png'
import advantage from '@/assets/img/solution-1/advantage.png'
import application_1 from '@/assets/img/solution-1/application/application-1.png'
import application_2 from '@/assets/img/solution-1/application/application-2.png'
import application_3 from '@/assets/img/solution-1/application/application-3.png'
import application_4 from '@/assets/img/solution-1/application/application-4.png'
import application_5 from '@/assets/img/solution-1/application/application-5.png'
import product_1 from '@/assets/img/solution-1/product/326kWh.png'
import product_2 from '@/assets/img/solution-1/product/652kWh.png'
import successCase_1 from '@/assets/img/solution-1/success-case/success-case-1.png'
import successCase_2 from '@/assets/img/solution-1/success-case/success-case-2.png'
import successCase_3 from '@/assets/img/solution-1/success-case/success-case-3.png'
import successCase_4 from '@/assets/img/solution-1/success-case/success-case-4.png'
import successCase_5 from '@/assets/img/solution-1/success-case/success-case-5.png'
import successCase_6 from '@/assets/img/solution-1/success-case/success-case-6.png'

export const solution_1: SolutionProps = {
  path: 'commercial-industrial',
  title: 'Commercial&Industrial Solution',
  description: 'Reliable energy supply across industrial, commercial, logistical, EV, and remote sectors.',
  cover: homepage,
  solutionCover: solutionCover,
  banner: banner,
  solution: [solution_image_1, solution_image_2],
  advantage: advantage,
  application: [
    {
      title: 'Industrial Parks and Factories',
      description:
        'Powers manufacturing processes, machinery, and lighting while providing backup power for critical operations.',
      image: application_1,
    },
    {
      title: 'Commercial Buildings',
      description:
        'Supplies energy for lighting, HVAC systems, elevators, and security systems in offices, shopping malls, and supermarkets.',
      image: application_2,
    },
    {
      title: 'Warehouses and Logistics Centers',
      description:
        'Supports refrigeration, lighting, and electric forklifts, while offering EV charging for delivery vehicles.',
      image: application_3,
    },
    {
      title: 'Electric Vehicle Charging Hubs',
      description: 'Provides daily charging services for EVs and ensures backup power for charging stations.',
      image: application_4,
    },
    {
      title: 'Microgrids for Remote Facilities',
      description:
        'Establishes independent power systems for hotels, resorts, mines, or medical facilities in remote or off-grid areas.',
      image: application_5,
    },
  ],
  product: [
    {
      title: 'CARUS-ESS-E326',
      image: product_1,
    },
    {
      title: 'CARUS-ESS-E652',
      image: product_2,
    },
  ],
  success_case: [
    {
      title: 'successCase_1',
      image: successCase_1,
    },
    {
      title: 'successCase_2',
      image: successCase_2,
    },
    {
      title: 'successCase_3',
      image: successCase_3,
    },
    {
      title: 'successCase_4',
      image: successCase_4,
    },
    {
      title: 'successCase_5',
      image: successCase_5,
    },
    {
      title: 'successCase_6',
      image: successCase_6,
    },
  ],
}
