import { Image } from 'react-bootstrap'
import Styles from './style.module.scss'

export default function Advantage({ data }) {
  return (
    <div className={Styles.wrapper} id='advantage'>
      <h2>Advantage</h2>
      <Image src={data} />
    </div>
  )
}
