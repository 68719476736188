import { Flex } from 'antd'
// import { Image } from 'react-bootstrap'
import banner from '@/assets/img/solution-banner.png'
import Styles from './style.module.scss'

export default function Index() {
  return (
    <Flex
      vertical
      align='center'
      justify='center'
      className={Styles.wrapper}
      style={{ backgroundImage: `url(${banner})` }}
    >
      <h2 className={Styles.title}>Let's Go Solar,Empower the Change,Together</h2>
      {/* <Flex justify='space-evenly' className={Styles.bottom}>
        <Flex vertical align='center'>
          <Image src='assets/svg/icon-safety.svg' />
          <div className={Styles.label}>Energy saving and safety</div>
        </Flex>
        <Flex vertical align='center'>
          <Image src='assets/svg/icon-comprehensive.svg' />
          <div className={Styles.label}>comprehensive</div>
        </Flex>
        <Flex vertical align='center'>
          <Image src='assets/svg/icon-24h.svg' />
          <div className={Styles.label}>24-hour maintenance</div>
        </Flex>
      </Flex> */}
    </Flex>
  )
}
