import { useNavigate } from 'react-router-dom'
import HotSpotImage from '@/components/hot-spot-image'
import ImageUrl from './carbon-text.png'
import Styles from './style.module.scss'

export default function Index() {
  const navigate = useNavigate()

  return (
    <div className={Styles.wrapper}>
      <HotSpotImage
        src={ImageUrl}
        areas={[
          {
            coords: [160, 508, 567, 630],
            onClick: () => navigate('/carbon'),
          },
        ]}
        hotSpotOptions={{
          nameSpace: 'carbon-page',
          // showDebug: true,
        }}
        wrapperClassName={Styles.imgWrapper}
      />
    </div>
  )
}
