import { useParams } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import { Flex } from 'antd'
import useLang from '@/utils/hooks/use-lang'
import PageWrapper from '@/components/page-wrapper'
import { i18nSolution, type SolutionProps } from '@/assets/content/solution'
import Contact from '@/pages/homepage/contact'
import Banner from './banner'
import AnchorPoint from './anchor-point'
import Advantage from './advantage'
import Application from './application'
import Product from './product'
// import SuccessCases from './success-case'
import SuccessCases from './case'

import Styles from './style.module.scss'

export default function Index() {
  const { type } = useParams()
  const [lang] = useLang()
  const content: SolutionProps = i18nSolution?.[lang].find(({ path }) => path === type)
  const { title, banner, solution, advantage, application, product, success_case } = content || {}

  return (
    <PageWrapper soul mainStyle={{ backgroundColor: '#fff' }}>
      <Banner banner={banner} title={title} />
      <AnchorPoint attrs={Object.keys(content)} />
      <Flex vertical className={Styles.solutionWrapper} id='solution'>
        {solution?.map(imgUrl => (
          <Image src={imgUrl} style={{ width: '100%' }} />
        ))}
      </Flex>
      <Advantage data={advantage} />
      <Application data={application} />
      <Product data={product} />
      <SuccessCases data={success_case} />
      <Contact />
    </PageWrapper>
  )
}
