import React, { forwardRef, useRef } from 'react'
import useImageMap from './use-image-map'
import type { HotSpotArea, UseImageMapOptions } from './type'

interface HotSpotImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  nameSpace?: string
  areas?: HotSpotArea[]
  hotSpotOptions?: UseImageMapOptions
  wrapperClassName?: string
}

const HotSpotImage = forwardRef<HTMLImageElement, HotSpotImageProps>(
  ({ areas = [], hotSpotOptions, wrapperClassName, ...imgProps }, ref) => {
    const internalRef = useRef<HTMLImageElement | null>(null)
    const { getMapElements, getDebugOverlays } = useImageMap(
      internalRef as React.RefObject<HTMLImageElement>,
      areas,
      hotSpotOptions as any
    )

    return (
      <div className={wrapperClassName} style={{ width: '100%', position: 'relative', display: 'inline-block' }}>
        <img
          {...imgProps}
          ref={node => {
            if (typeof ref === 'function') ref(node)
            else if (ref) ref.current = node
            internalRef.current = node
          }}
          useMap={`#${hotSpotOptions?.nameSpace}`}
          style={{ width: '100%', height: 'auto' }}
          alt='hot-spot'
        />
        {getMapElements()}
        {getDebugOverlays()}
      </div>
    )
  }
)
export default HotSpotImage
