import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { NavDropdown, Image } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Brand from '../brand'
import Styles from './style.module.scss'
import ClassName from 'clsx'
import useLang from '@/utils/hooks/use-lang'
import { menus as _menus } from './menu.js'

const langs = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Español',
    value: 'es',
  },
  {
    label: '简体中文',
    value: 'cn',
  },
]

/**
 * soul: 空灵状态的导航（浮顶、背景透明）
 */
export default function Index({ expand = 'md', soul = false, dark = false }) {
  const [hover, setHover] = useState(false)
  const navigate = useNavigate()
  const [scroll, setScroll] = useState(0)
  const [lang, setLang] = useLang()
  const handleScroll = () => {
    const position = window.scrollY
    setScroll(position)
  }
  const menus = _menus(lang)

  const handleLangChange = useCallback(l => {
    // setLang(l)
    localStorage.setItem('lang', l)

    setTimeout(() => {
      window.location.reload()
    })
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const needSticky = soul && scroll > 0

  return (
    <Navbar
      key={expand}
      expand={true}
      className={ClassName(
        Styles.nav,
        soul && Styles.soul,
        dark && Styles.dark,
        (needSticky || hover || !soul) && Styles.sticky
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={ClassName(needSticky && Styles.white)} onClick={() => navigate('/')}>
        {(needSticky || !soul || hover || dark) && <Brand />}
        {soul && !dark && scroll === 0 && !hover && <Brand white />}
      </div>
      <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}>
        <Image src='assets/menu.png' />
      </Navbar.Toggle>
      <Navbar.Offcanvas
        id={`offcanvasNavbar-expand-${expand}`}
        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
        placement='end'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
            <Brand />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className='justify-content-start flex-grow-1 pe-3'>
            {menus.map((d, index) => {
              if (!!d?.children?.length) {
                return (
                  <NavDropdown key={d.title} title={d.title} id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    {d.children?.map((_d, _index) => (
                      <NavDropdown.Item key={_d.title} className={Styles.subtitle} onClick={() => navigate(_d.path)}>
                        {_d.title}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                )
              }
              return (
                <Nav.Link key={d.title} className={Styles.title} onClick={() => navigate(d.path)}>
                  {d.title}
                </Nav.Link>
              )
            })}
          </Nav>
          <div className={Styles.language}>
            <NavDropdown title={<span className={Styles.text}>{lang === 'cn' ? '简体中文' : lang}</span>}>
              {(langs || [])?.map(({ label, value }) => (
                <NavDropdown.Item key={value} onClick={() => handleLangChange(value)}>
                  {label}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </div>
          {/* <Form className='d-flex'>
              <Form.Control type='search' placeholder='Search' className='me-2' aria-label='Search' />
              <Button variant='outline-success'>Search</Button>
            </Form> */}
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  )
}
