import { Flex } from 'antd'
import { Image } from 'react-bootstrap'
import ClassName from 'clsx'
import PageWrapper from '@/components/page-wrapper'
import ImageRiver from '@/assets/img/river.png'
import IconCredit from '@/assets/svg/icon-credit.svg'
import IconPower from '@/assets/svg/icon-power.svg'
import IconEnergy from '@/assets/svg/icon-energy.svg'
import ImageMatrix from '@/assets/img/matrix.png'
import Contact from '@/pages/homepage/contact'
import Styles from './style.module.scss'
import banner from './banner.jpg'
import solution_1 from './solution-1.png'
import solution_2 from './solution-2.png'
import teamImg from '@/assets/img/team.png'
import SwiperComponent from '@/components/swiper-intro'

const bk = 'assets/pic_banner_3_dark.png'

const cardData = [
  {
    title: 'Energy',
    icon: IconEnergy,
    description: 'Commitment to maximizing energyproduction and resource utilization',
  },
  {
    title: 'Power and Sustainability',
    icon: IconPower,
    description: 'Commitment to maximizing energyproduction and resource utilization',
  },
  {
    title: 'conservation and clean energy',
    icon: IconCredit,
    description: 'Commitment to maximizing energyproduction and resource utilization',
  },
]

const slides = [
  {
    title: 'Sean Liang',
    position: 'CEO',
    description: [
      'Extensive experience in capital operations and investment management, leading financing for advanced industries such as AI, smart hardware, energy storage.',
      'Entrepreneurial experience across global green energy sectors.',
      'Founder of the Carus commercial energy storage brand, with over 20-years in executive management and',
      'project development in sustainable technology.',
      ' Career Highlights – ',
      'Co-founder of the North American PhG home energy storage brand, leading the development of the product line and the establishment of marketing channels.',
      'Previously served as Vice President and COO among other core executive positions at LY Group, leading the creation of multiple product lines, business departments, and independent subsidiary platforms.',
      'Founded Shenzhen CYF Company, achieving over a hundred million in revenue in two years, and was fully acquired by a listed company in 2018.',
    ],
  },
  {
    title: 'Daniel Sun',
    position: 'EPC Sales CEO',
    description: [
      'Licensed engineer with expertise in General Construction (B), Thermal and Photovoltaic Solar Energy Systems (C46), Flooring (C15), Hazardous Material Removal (HAZ), and Asbestos Abatement (ASB).',
      'Skilled in comprehensive remediation services, including',
      'water, fire, microbial, odor, and biohazard remediation, adhering to EPA, AQMD, OSHA, and fire department standards.',
    ],
  },
  {
    title: 'Jack Chen',
    position: 'CTO',
    description:
      "Holds dual master's degrees from Wayne State University, with experience in various Fortune 500 companies including General Motors and CATL. Entrepreneurial experience in the internet, new energy vehicles, and energy storage industries. Accumulated over 30 years of engineering, management, sales, and trade expertise.",
  },
  {
    title: 'Justin',
    position: 'Director of Business Development',
    description: [
      '12 Years in Advanced Energy System with primary focus in business development in commercial solar industry. Educating customers with everything related to solar and beef up with his financial background on project/investment analysis',
      'Self-Employed/CEO of Justin Wilbur LLC',
      'Financial Representative in COUNTRY Financial, provided sophisticated mid term and long- term financial suggestions through out a series of analysis.',
    ],
  },
  {
    title: 'Alexia Wang',
    position: 'Marketing & Compliance Specialist',
    description: [
      'Environmental Economics and Policy major at UC Berkeley. Exploring the natural environment and economics development through the evolution of renewable energy, and explore the optimal solution between environmental protection, government policies and economic development.',
      'Career Highlights – ',
      'University of California Berkeley (UC Berkley), undergraduate – Environmental Economics & Policy',
    ],
  },
  {
    title: 'Dr Liu',
    position: 'Carus ESG Expert',
    description: [
      "8 years of expertise in collaborating with Apple's ESG team.",
      "Exceptional contributions to Apple's ESG framework and carbon footprint management systems.",
    ],
  },
  {
    title: 'Shanshan',
    position: 'Data Analyst ',
    description: [
      'BS in Mathematics and MS in Econometrics from New York University, with coursework in corporate finance and valuation. Expertise in statistical modeling, predictive analytics, and financial analysis to transform complex datasets into actionable insights. Applies econometric frameworks to optimize business performance, assess financial risk, and align data-driven strategies with sustainability and growth objectives.',
    ],
  },
]

export default function Index() {
  return (
    <PageWrapper soul>
      <Flex
        vertical
        align='center'
        justify='center'
        className={ClassName(Styles.bannerWrapper, Styles.darkMask)}
        style={{
          background: `url(${banner}) no-repeat center/cover`,
        }}
      >
        <h2>Our Company</h2>
        <div>
          Carus Energy (CARUSG) is an innovative company specializing in the design, development, and cloud services of
          renewable energy systems for commercial and industrial applications. Our goal is to provide our clients with
          reliable, efficient, and cost-effective energy solutions. We aim to help them reduce energy costs, improve
          energy efficiency, and promote sustainable business practices Headquartered in San Jose, CA, United States,
          our team was established in 2016, bringing together engineers dedicated to advancing PV and BESS technologies.
          With over 500 MWh of installed capacity across the U.S., our highly skilled engineers excel in hardware,
          software, and system-level design, ensuring innovative and reliable energy solutions for commercial and
          industrial applications.
        </div>
      </Flex>
      <div
        className={ClassName(Styles.wrapper, Styles.darkMask)}
        style={{ background: `url(${ImageRiver}) no-repeat center/cover` }}
      >
        <Flex>
          <h2>About Carus EPC</h2>
          <div className={Styles.info}>
            We are the architects of comprehensive, end- to-end energy solutions, embracing every facet of solar
            photovoltaic(PV) and BESS technology from conceptualization to commissioning. Our expertise encompasses
            modeling and designing, hardware integration, supply chain management, environmental engineering, energy
            management software, control and monitoring systems, cloud computation, and data collection.
          </div>
        </Flex>
        <div className={Styles.gridCard}>
          {cardData?.map(({ icon, title, description }) => (
            <Flex key={title} vertical align='center' className={Styles.card}>
              <Image src={icon} />
              <h5>{title}</h5>
              <div>{description}</div>
            </Flex>
          ))}
        </div>
      </div>
      <Flex
        vertical
        align='flex-end'
        className={ClassName(Styles.imageWrapper, Styles.darkMask, Styles.right)}
        style={{
          background: `url(${solution_1}) no-repeat center/cover`,
        }}
      >
        <h2>Vision</h2>
        <div>
          We aim to be your premium solution provider for renewable energy applications, empowering our clients to
          establish greener, more economical, and smarter power systems.
        </div>
      </Flex>
      <Flex
        vertical
        className={ClassName(Styles.imageWrapper, Styles.darkMask, Styles.left)}
        style={{
          background: `url(${solution_2}) no-repeat center/cover`,
        }}
      >
        <h2>Mission</h2>
        <div>
          Offering the very best renewable energy solutions covering smart power management, green energy design, data
          analytics, visualized control systems, and more
        </div>
      </Flex>
      <Flex
        align='flex-end'
        className={Styles.storyWrapper}
        style={{ background: `url(${ImageMatrix}) no-repeat center/85%` }}
      >
        <Image src={bk} />
        <div>
          <h2>development history</h2>
          <Flex vertical className={Styles.storyContent}>
            <div>
              Carus EPC is a comprehensive energyS PV, BESS, EVsolutions provider, integrating expertise acroscharging
              infrastructure, and various enironmental engineering projects.
            </div>
            <div>
              uses on providingAs a whole, Carus EPC operates throuch two key branches: Carus Energy, which fooEV
              charging projects, and EPC Energy, whicend-to-end solutions for PV, BESS, andi specializes in
              thedevelopment of commercial and industial energy storage systems.
            </div>
            <div>
              Headquartered in San Jose, CA, our core team was formed in 2016, bringing together engineersdedicated to
              PV and BESS technologie.. With over 500 MWh of installed capacity ac!oss the U.S., ourare, software, and
              system-level design and imhighly skilled engineers excel in hardwolementation.delivering innovative and
              reliable energy solutions.
            </div>
          </Flex>
        </div>
      </Flex>
      <div className={Styles.teamWrapper}>
        <Flex vertical align='center' className={Styles.top}>
          <h2>Meet Our Core Team </h2>
          <div>
            Our core team consists of industry leaders with extensive experience in the energy sector. Together, they
            drive innovation and ensure the highest quality of service for our clients.
          </div>
          {/* <Image src={teamImg} className={Styles.team} /> */}
          <div className={Styles.team}>
            <SwiperComponent slides={slides} />
          </div>
        </Flex>
      </div>

      <Contact />
    </PageWrapper>
  )
}
