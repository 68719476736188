import ClassName from 'clsx'
import Styles from './style.module.scss'

interface ImageWrapperProps {
  backgroundImage: string
  className: string
  children: React.ReactNode
}

export default function ImageWrapper({ backgroundImage, className, children }: Partial<ImageWrapperProps>) {
  return (
    <div className={ClassName(Styles.wrapper, className)} style={{ backgroundImage: `url(${backgroundImage})` }}>
      {children}
    </div>
  )
}
