import { solution_data, service_center, about_us } from '@/utils/data'

export const data = lang => {
  const solution1 = solution_data[lang]
  const service = service_center[lang]
  const about = about_us[lang]

  const solution = [
    {
      id: 'solution-1',
      title: 'Commercial&Industrial Solution',
      path: '/solution/commercial-industrial',
    },
    {
      id: 'solution-2',
      title: 'Residential Solution',
      path: '/solution/residential',
    },
    {
      id: 'solution-3',
      title: 'Emergency&Disaster Relief Solution',
      path: '/solution/emergency-disaster-relief',
    },
    {
      id: 'solution-4',
      title: 'Utility-Scale Solution',
      path: '/solution/utility-scale',
    },
  ]

  const d = {
    en: [
      {
        title: 'Product',
        path: '/product',
        children: [
          {
            title: 'PCS',
            path: '/',
          },
          {
            title: 'Battery Storage',
            path: '/',
          },
          {
            title: 'ESS',
            path: '/',
          },
          {
            title: 'EV Charging',
            path: '/',
          },
          {
            title: 'EMS & APP',
            path: '/',
          },
        ],
      },
      {
        title: 'Solution',
        path: '',
        children: solution,
      },
      {
        title: 'carbon',
        path: '/carbon',
        // children: about,
      },
      {
        title: 'Resource Center',
        path: '',
        children: service,
      },
      {
        title: 'About Us',
        path: '/about-us',
        // children: about,
      },
      {
        title: 'News',
        path: '/news',
      },
    ],
    es: [
      {
        title: 'Producto',
        path: '/product',
        children: [
          {
            title: 'Sistema de Conversión de Energía',
            path: '/',
          },
          {
            title: 'Almacenamiento de Baterías',
            path: '/',
          },
          {
            title: 'Sistema de Almacenamiento de Energía',
            path: '/',
          },
          {
            title: 'Carga de Vehículos Eléctricos (EV Charging)',
            path: '/',
          },
          {
            title: 'EMS (Sistema de Gestión de Energía) y Aplicación (APP)',
            path: '/',
          },
        ],
      },
      {
        title: 'Solución',
        path: '',
        children: solution,
      },
      {
        title: 'carbono',
        path: '/carbon',
        // children: about,
      },
      // {
      //   title: 'Centro de Recursos',
      //   path: '',
      //   children: service,
      // },
      {
        title: 'Sobre Nosotros',
        path: '/about-us',
        // children: about,
      },
      // {
      //   title: 'Noticias',
      //   path: '/news',
      // },
    ],
    cn: [
      {
        title: '产品',
        path: '/product',
        children: [
          {
            title: '电力控制系统',
            path: '/',
          },
          {
            title: '储能电池柜',
            path: '/',
          },
          {
            title: 'ESS一体机',
            path: '/',
          },
          {
            title: 'EV charger',
            path: '/',
          },
          {
            title: 'EMS & APP',
            path: '/',
          },
        ],
      },
      {
        title: '解决方案',
        path: '/solution',
        children: solution,
      },
      // {
      //   title: '服务中心',
      //   path: '',
      //   children: service,
      // },
      {
        title: '碳足迹',
        path: '/carbon',
        // children: about,
      },
      {
        title: '关于我们',
        path: '/about-us',
        // children: about,
      },
      // {
      //   title: '新闻中心',
      //   path: '/news',
      // },
    ],
  }

  return d[lang].filter(({ title }) => !['产品', 'Producto', 'Product', 'Resource Center', 'News'].includes(title))
}
