import { solution_data, service_center, about_us } from '@/utils/data'

export const menus = lang => {
  const solution1 = solution_data[lang]
  const service = service_center[lang]
  const about = about_us[lang]

  const solution = [
    {
      id: 'solution-0',
      title: 'Solution',
      path: '/solution',
    },
    {
      id: 'solution-1',
      title: 'Commercial&Industrial Solution',
      path: '/solution/commercial-industrial',
    },
    {
      id: 'solution-2',
      title: 'Residential Solution',
      path: '/solution/residential',
    },
    {
      id: 'solution-3',
      title: 'Emergency&Disaster Relief Solution',
      path: '/solution/emergency-disaster-relief',
    },
    {
      id: 'solution-4',
      title: 'Utility-Scale Solution',
      path: '/solution/utility-scale',
    },
  ]

  const data = {
    en: [
      {
        title: 'Home',
        path: '/',
      },
      {
        title: 'Solution',
        path: '/solution',
        children: solution,
      },
      {
        title: 'Carbon',
        path: '/carbon',
      },
      // {
      //   title: 'Product',
      //   path: '/product',
      // },
      // {
      //   title: 'Resource Center',
      //   path: '',
      //   children: service,
      // },
      // {
      //   title: 'News',
      //   path: '/news',
      // },
      {
        title: 'About Us',
        path: '/about-us',
        // children: about,
      },
    ],
    es: [
      {
        title: 'Página de inicio',
        path: '/',
      },
      {
        title: 'Solución',
        path: '/solution',
        children: solution,
      },
      // {
      //   title: 'Producto',
      //   path: '/product',
      // },
      {
        title: 'Carbono',
        path: '/carbon',
      },
      // {
      //   title: 'Centro de Recursos',
      //   path: '',
      //   children: service,
      // },
      {
        title: 'Noticias',
        path: '/news',
      },
      {
        title: 'Sobre Nosotros',
        path: '/about-us',
        // children: about,
      },
    ],
    cn: [
      {
        title: '首页',
        path: '/',
      },
      {
        title: '解决方案',
        path: '/solution',
        children: solution,
      },
      // {
      //   title: '产品',
      //   path: '/product',
      // },
      {
        title: '碳足迹',
        path: '/carbon',
      },
      // {
      //   title: '服务中心',
      //   path: '',
      //   children: service,
      // },
      // {
      //   title: '新闻',
      //   path: '/news',
      // },
      {
        title: '关于我们',
        path: '/about-us',
        // children: about,
      },
    ],
  }

  return data[lang]
}
