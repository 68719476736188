import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from '@/components/layout'
import Homepage from '@/pages/homepage'
import News from '@/pages/news'
import NewsDetail from '@/pages/news/detail'
import About from '@/pages/about'
import Join from '@/pages/join'
import Contact from '@/pages/contact'
import Solution from '@/pages/solutions/index'
import SolutionDetail from '@/pages/solutions/[type]'
import Solution1 from '@/pages/solutions/ci-energy-solution'
import Solution2 from '@/pages/solutions/clouding-service'
import Solution3 from '@/pages/solutions/design-consulting'
import Solution4 from '@/pages/solutions/financial-service'
import Carbon from '@/pages/carbon'
// import Service from '@/pages/service-center'
// import Service_1 from '@/pages/service-center/download'
// import Service_2 from '@/pages/service-center/quality'
// import Service_3 from '@/pages/service-center/support'
// import Service_4 from '@/pages/service-center/train'
// import Service_5 from '@/pages/service-center/after-sale'
import Product from '@/pages/product'
import ProductDetail from '@/pages/product/detail'
import AboutUs from '@/pages/about-us'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ThemeProvider } from 'react-bootstrap'

const LayoutComponents = props => <Layout {...props}></Layout>

export const GlobalContext = React.createContext(null)

function App() {
  const [globalProps, setGlobalProps] = useState({
    lang: localStorage.lang || 'en',
  })

  return (
    <GlobalContext.Provider value={{ globalProps, setGlobalProps }}>
      <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm']} minBreakpoint='sm'>
        <Routes>
          <Route path='/' element={<LayoutComponents />}>
            {/* 首页 */}
            <Route index element={<Homepage />} />

            {/* 解决方案路由组 */}
            <Route path='/solution'>
              <Route index element={<Solution />} />
              <Route path=':type' element={<SolutionDetail />} />
            </Route>

            {/* 碳足迹 */}
            <Route path='/carbon' element={<Carbon />} />

            {/* 产品 */}
            <Route path='/product' element={<Product />} />
            <Route path='/productDetail' element={<ProductDetail />} />

            {/* 产品路由 */}
            {/* <Route path='product'>
              <Route index element={<Product />} />
              <Route path=':id' element={<ProductDetail />} />
            </Route> */}

            {/* 服务中心 */}
            {/* <Route  path='/service/download' element={<Service />} />
            <Route  path='/service/quality' element={<Service />} />
            <Route  path='/service/support' element={<Service />} />
            <Route  path='/service/train' element={<Service />} />
            <Route  path='/service/afterSale' element={<Service />} /> */}
            {/* 新闻 */}
            <Route path='/news' element={<News />} />
            <Route path='/news-detail' element={<NewsDetail />} />
            {/* 关于我们 */}
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/about' element={<About />} />
            <Route path='/join' element={<Join />} />
            <Route path='/contact' element={<Contact />} />
            {/* 404 */}
            <Route path='*' element={<div>404</div>} />
          </Route>
        </Routes>
      </ThemeProvider>
    </GlobalContext.Provider>
  )
}

export default App
