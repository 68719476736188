import Styles from './style.module.scss'

const parts = ['solution', 'advantage', 'application', 'product', 'success_case', 'contact_us']

export default function AnchorPoint({ attrs = [] }) {
  return (
    <div className={Styles.anchorPoint}>
      {parts
        ?.filter(d => [...attrs, 'contact_us']?.includes(d))
        ?.map((part, index) => (
          <div key={index} onClick={() => document.querySelector(`#${part}`)?.scrollIntoView()}>
            {part.split('_').join(' ')}
          </div>
        ))}
    </div>
  )
}
