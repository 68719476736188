import homepage from '@/assets/img/solution-4/homepage.jpg'
import solution from '@/assets/img/solution-4/solution.jpg'
import Banner from '@/assets/img/solution-4/banner.png'
import advantage from '@/assets/img/solution-4/advantage.png'
import solution_image_1 from '@/assets/img/solution-4/solution-image-1.png'
import application_1 from '@/assets/img/solution-4/application/application-1.png'
import application_2 from '@/assets/img/solution-4/application/application-2.png'
import application_3 from '@/assets/img/solution-4/application/application-3.png'
import application_4 from '@/assets/img/solution-4/application/application-4.png'
import application_5 from '@/assets/img/solution-4/application/application-5.png'
import product_1 from '@/assets/img/solution-4/product/product-1.png'
import successCase_1 from '@/assets/img/solution-4/success-case/success-case-1.png'
import successCase_2 from '@/assets/img/solution-4/success-case/success-case-2.png'
import successCase_3 from '@/assets/img/solution-4/success-case/success-case-3.png'

export const solution_4 = {
  path: 'utility-scale',
  title: 'Utility-Scale Solution',
  description:
    'Solar storage solutions for grid-scale renewables, stability, economic efficiency, microgrid reliability, and disaster resilience',
  cover: homepage,
  solutionCover: solution,
  banner: Banner,
  solution: [solution_image_1],
  advantage: advantage,
  application: [
    {
      title: 'Grid-Scale Renewable Energy Projects',
      description:
        'Supports large solar farms that supply electricity to the grid, often in regions with high solar irradiance.',
      image: application_1,
    },
    {
      title: 'Grid Stabilization',
      description: 'Provides ancillary services to maintain grid stability and prevent blackouts.',
      image: application_2,
    },
    {
      title: 'Energy Arbitrage',
      description:
        'Stores solar energy when generation exceeds demand and sells it to the grid during high-price periods.',
      image: application_3,
    },
    {
      title: 'Microgrid Support',
      description: 'Acts as a backbone for microgrids in remote or island areas, ensuring a reliable power supply.',
      image: application_4,
    },
    {
      title: 'Disaster Recovery and Resilience',
      description: 'Provides backup power and grid support in the event of natural disasters or grid failures.',
      image: application_5,
    },
  ],
  product: [
    {
      title: 'S430H201、S556H201',
      image: product_1,
    },
  ],
  success_case: [
    // {
    //   title: 'successCase_1',
    //   image: successCase_1,
    // },
    // {
    //   title: 'successCase_2',
    //   image: successCase_2,
    // },
    // {
    //   title: 'successCase_3',
    //   image: successCase_3,
    // },
  ],
}
