import { Fade } from 'react-awesome-reveal'
import PageWrapper from '@/components/page-wrapper'
import Carousel from './carousel'
import BannerCards from './banner-cards'
import Accordion from './accordion'
import EarthPoint from './earth-point'
import Carbon from './carbon'
import Supply from './supply'
import Cycle from './cycle'
import Styles from './style.module.scss'
import Contact from './contact'

function ModuleWrapper({ children, style }: any) {
  return (
    <div className={Styles.moduleWrapper} style={style}>
      {children}
    </div>
  )
}

function BottomWrapper({ children }) {
  return <div className={Styles.bottomWrapper}>{children}</div>
}

const fadeProps = {
  cascade: true,
  triggerOnce: true,
  damping: 0.1,
}

export default function Index() {
  return (
    <PageWrapper soul dark darkHeader>
      <div>
        <Carousel />
        {/* <BannerCards /> */}
        <ModuleWrapper>
          <Fade {...fadeProps}>
            <Accordion />
          </Fade>
        </ModuleWrapper>
        <ModuleWrapper style={{ position: 'sticky', top: '0px' }}>
          <Fade {...fadeProps}>
            <EarthPoint />
          </Fade>
        </ModuleWrapper>
        <Fade {...fadeProps}>
          <Carbon />
        </Fade>
      </div>
      <BottomWrapper>
        <Fade {...fadeProps} style={{ position: 'relative', zIndex: 2 }}>
          {/* <Supply /> */}
          <Cycle />
          <Contact shadow />
        </Fade>
      </BottomWrapper>
    </PageWrapper>
  )
}
