import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import styles from './style.module.scss'
import 'swiper/css'
import 'swiper/css/navigation'

interface SlideData {
  title: string
  position: string
  description: string[] | string
}

const SwiperCarousel = ({ slides }: { slides: SlideData[] }) => {
  return (
    <div className={styles.container}>
      <Swiper
        modules={[Navigation]}
        navigation={{
          prevEl: `.${styles.prev}`,
          nextEl: `.${styles.next}`,
        }}
        slidesPerView={3}
        centeredSlides
        spaceBetween={30}
        initialSlide={0}
        loop
        onSlideChange={() => console.log('slide change')}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {({ isActive }) => (
              <div className={`${styles.slide} ${isActive ? styles.active : ''}`}>
                <div className={styles.content}>
                  <h5 className={styles.title}>{slide.title}</h5>
                  <div className={styles.position}>{slide?.position}</div>
                  <p className={styles.description}>{slide.description}</p>
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}

        {/* 自定义导航按钮 */}
        <div className={`${styles.navigation} ${styles.prev}`}>‹</div>
        <div className={`${styles.navigation} ${styles.next}`}>›</div>
      </Swiper>
    </div>
  )
}

export default SwiperCarousel
